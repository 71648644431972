import { graphql } from "gatsby"
import React from "react"
import { useButtonText } from "../hooks/useButtonText"
import { useLanguage } from "../hooks/useLanguage"
import { P } from "./fonts"
import ReadMore from "./readMore"
export default function Feature({ feature }) {
    const { switchLanguage } = useLanguage()

    const btnText = useButtonText()

    return (
        <div className='flex align-center m-align-start m-flex-c '>
            <div className='feature-icon b1 mr30 m-ma'>
                <img alt='' src={feature.icon.url} />
            </div>
            <div className='m-pt10'>
                <P className='mb5 m-font-medium '>{switchLanguage(feature.nameJp, feature.name)}</P>
                {feature.link ? <ReadMore children={switchLanguage(btnText.readTitleJp, btnText.readTitle)} className='m-font-medium' hideArrowMobile={false} to={feature.link} /> : null}
            </div>
        </div>
    )
}

export const query = graphql`
    fragment Feature on DatoCmsFeature {
        icon {
            url
        }
        link
        name
        nameJp
    }
`
