import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { useLanguage } from "../hooks/useLanguage"
import AssociatedBrands from "./associatedBrands"
import CtaButton from "./ctaButton"
import { H2, P } from "./fonts"
import IconNText from "./iconNText"
import LatestNews from "./latestNews"
import Link from "./link"
import Overlay from "./overlay"
import OverlayConatiner from "./overlayConatiner"
import ProductFeatures from "./productFeatures"
import Spacer from "./spacer"
import Testiomnal from "./testiomnal"
import VideoOrImage from "./videoOrImage"

import "swiper/css"
import "swiper/css/navigation"

export const ModularContent = ({ modules }) => {
    return (
        <section>
            {modules.map(module => {
                if (module.id.includes("Introduction")) return <ProductFeatures key={module.id} info={{ ...module }} features={module.features} bgWhite={false} />
                if (module.id.includes("Testimonial")) return <Testiomnal key={module.id} testiomnal={{ quote: module.quote, quoteJp: module.quoteJp, name: module.customerName, nameJp: module.customerNameJp, detail: module.customerDetail, detailJp: module.customerDetailJp }} />
                if (module.id.includes("CardCarousel")) return <CardCarousel key={module.id} {...module} />
                if (module.id.includes("FullWidthCard")) return <FullCard key={module.id} {...module} />
                if (module.id.includes("Subheading")) return <Subheading key={module.id} {...module} />
                if (module.id.includes("Laptop")) return <LaptopCard key={module.id} {...module} />
                if (module.id.includes("TwoCard")) return <TwoCards key={module.id} {...module} />
                if (module.id.includes("LogoBar")) return <LogoBar key={module.id} {...module} />
                if (module.id.includes("LatestNews")) return <News key={module.id} {...module} />
                if (module.id.includes("Heading")) return <Heading key={module.id} {...module} />
                if (module.id.includes("Spacer")) return <Spacer key={module.id} />
                return null
            })}
        </section>
    )
}

const Subheading = ({ heading, headingJp, paragraph, paragraphJp }) => {
    const { switchLanguage } = useLanguage()
    return (
        <div>
            <div className='pt200 p60 m-p20 pb100  m-pl20 m-pr20 m-pt80 m-pb100'>
                <ReactMarkdown className='h3 text-grey ma max-1000 m-text-left m-text-black m-h3 post--content'>{switchLanguage(headingJp, heading)}</ReactMarkdown>
            </div>
            <div className='pb200 p60 m-p20 m-pl20 m-pr20 m-pb100'>
                <ReactMarkdown className='text-grey p ma max-1000 m-text-left m-text-black m-h3 post--content'>{switchLanguage(paragraphJp, paragraph)}</ReactMarkdown>
            </div>
        </div>
    )
}

const LaptopCard = ({ image, icon, imageMobile, title, titleJp, caption, captionJp, textLight, backgroundVideoUrl }) => {
    const { switchLanguage } = useLanguage()
    return (
        <>
            <div className='p60 m-p0 flex laptop-card-wrapper'>
                <div className='max-1600 w-100 ma flex'>
                    <div className='cards w-100 flex flex-wrap m-ml20 m-mr20'>
                        <div className={"card laptop-card t-flex t-flex-column br-10 ratio-2-1 m-ratio-1-15 pos-rel overflow-hidden t-ratio-1-2 m-mb20 w-100 "}>
                            <div className={"max-500 m-pb80  pos-rel z-2 pos-ab t-pos-rel p60 " + (textLight ? "" : "text-white") + " m-p0  m-pt20 "}>
                                <div className={"icon-wrapper mb20 pos-rel  m-ml20 m-mr20"}>{icon ? <img className='bg-image' alt='Product Icon' src={icon?.url} /> : ""}</div>
                                <h2 className={"m0 h2  m-pl20 m-pr20"}>{switchLanguage(titleJp, title)}</h2>
                                <div className={"caption mt20 m-pl20 m-pr20"}>{switchLanguage(captionJp, caption)}</div>
                            </div>

                            <VideoOrImage className={"bg-image m-br-08 " + (imageMobile ? "t-hide" : "")} image={image} videoWhen={backgroundVideoUrl} video={backgroundVideoUrl} alt='Product Image' />
                            <GatsbyImage alt='Mobile Product Image' className='t-show mta block' image={imageMobile?.gatsbyImageData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const TwoCards = ({ cards }) => {
    const { switchLanguage } = useLanguage()
    return (
        <>
            <div className='p60 m-p0 flex two-card-wrapper'>
                <div className='max-1600 w-100 ma flex'>
                    <div className='cards w-100 flex flex-wrap gap-20'>
                        {cards.map(card => {
                            return (
                                <div key={card.id} className={"card br-10 mb60 ratio-2-1 m-ratio-1-2 pos-rel overflow-hidden m-mb20  w-50-10 m-100 m-ml20 m-mr20  "}>
                                    <div className={"max-500  pos-rel z-2 pos-ab p60 " + (card.textLight ? "" : "text-white") + " m-p20"}>
                                        <div className={"icon-wrapper mb20 pos-rel "}>{card.icon ? <img className='bg-image' alt='Product Icon' src={card.icon?.url} /> : ""}</div>
                                        <h2 className={"m0  h3 "}>{switchLanguage(card.titleJp, card.title)}</h2>
                                        <div className={"caption mt20 "}>{switchLanguage(card.captionJp, card.caption)}</div>
                                    </div>

                                    <VideoOrImage className={"bg-image m-br-08 " + (card.imageMobile ? "t-hide" : "")} image={card.image} videoWhen={card.backgroundVideoUrl} video={card.backgroundVideoUrl} alt='Product Image' />
                                    <GatsbyImage alt='Mobile Product Image' className='t-show bg-image ' image={card.imageMobile?.gatsbyImageData} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

const FullCard = ({ link, title, titleJp, videoUrl, darkText, mobileVideoUrl, image, mobileImage, icon, buttonText, buttonTextJp, content, contentJp }) => {
    const { switchLanguage } = useLanguage()
    const As = link.startsWith("/") ? Link : "a"
    const linkProps = link.startsWith("/") ? { to: link, class: "hover-image--zoom link br-10 overflow-hidden" } : { href: link, target: "_blank", rel: "noreferrer", class: "hover-image--zoom link block br-10 overflow-hidden" }
    return (
        <div className='p60 m-p20'>
            <section className={`max-1600 w-100 ma ${darkText ? "" : "text-white"}`}>
                <As {...linkProps}>
                    <OverlayConatiner className='pt50p bg-grey t-pt100 m-pt150 m-br0'>
                        <Overlay className='z-2  flex flex-col  p5p border-box justify-center pos-abs m-justify-start m-pt30 m-pb30'>
                            <div>
                                <img src={icon.url} className='mb30 m-mb1-' alt='' />
                            </div>
                            <H2 className='m0 max-450 m-max-300 mb30 m-mb20'>{switchLanguage(titleJp, title)}</H2>
                            <P className='mb60 max-450 m-max-300 m0 m-mb20'>{switchLanguage(contentJp, content)}</P>
                            <div className='flex'>
                                <CtaButton children={switchLanguage(buttonTextJp, buttonText)} />
                            </div>
                        </Overlay>
                        <VideoOrImage className='z-1 cover flex bg-image filter-brightness' mobileImage={mobileImage} mobileVideo={mobileVideoUrl} videoWhen={videoUrl} video={videoUrl} image={image} />
                    </OverlayConatiner>
                </As>
            </section>
        </div>
    )
}

const Heading = ({ heading, headingJp, paragraph, paragraphJp }) => {
    const { switchLanguage } = useLanguage()
    return (
        <div className='max-1600 ma text-center pl60 pr60  '>
            <Spacer />
            <H2 className='mb30 max-500 ma'>{switchLanguage(headingJp, heading)}</H2>
            <P className='max-500 ma text-grey'>{switchLanguage(paragraphJp, paragraph)}</P>
            <Spacer />
        </div>
    )
}

const LogoBar = ({ title, titleJp, logos }) => {
    const { switchLanguage } = useLanguage()
    return (
        <div className='brands p60 text-center max-1000 ma m-p20 m-pb60 m-pt40'>
            <H2 className='mb60'>{switchLanguage(titleJp, title)}</H2>
            <AssociatedBrands asscoiatedBrands={logos} />
        </div>
    )
}

const CardCarousel = ({ id, title, titleJp, cards }) => {
    const { switchLanguage } = useLanguage()
    return (
        <div className='products m-pt60'>
            <p className='m0 h3 p60 m-p20 max-1600 ma flex justify-between' id={id}>
                <span>{switchLanguage(titleJp, title)}</span>
                <menu className='mla'>
                    <button id={`prevEl-${id}`}>
                        <span className='prev' />
                    </button>
                    <button id={`nextEl-${id}`}>
                        <span className='next' />
                    </button>
                </menu>
            </p>
            <Carousel cards={cards} id={id} />
            <Spacer />
        </div>
    )
}

const Carousel = ({ cards, id }) => {
    const [distanceToMoveCarousel, setDistanceToMoveCarousel] = useState(0)

    useEffect(() => {
        function calculateDistanceToMove() {
            const container = document.querySelector(`#${id}`)
            setDistanceToMoveCarousel(() => Math.round(container.getBoundingClientRect().left) + parseInt(window.getComputedStyle(container, null).getPropertyValue("padding-left")))
        }
        calculateDistanceToMove()

        window.addEventListener("resize", calculateDistanceToMove)
        return () => window.removeEventListener("resize", calculateDistanceToMove)
    }, [id])

    return (
        <Swiper
            spaceBetween={30}
            breakpoints={{
                0: { slidesPerView: 1.2, spaceBetween: 20 },
                500: { slidesPerView: 1.2, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 30 },
                1450: { slidesPerView: 3, spaceBetween: 30 },
            }}
            slidesOffsetBefore={distanceToMoveCarousel}
            slidesOffsetAfter={distanceToMoveCarousel}
            modules={[Navigation]}
            navigation={{ nextEl: `#nextEl-${id}`, prevEl: `#prevEl-${id}` }}>
            {cards.map(card => (
                <SwiperSlide key={id}>
                    <Card {...card} />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

const Card = ({ title, titleJp, icon, buttonText, buttonTextJp, overline, overlineJp, image, imageMobile, backgroundVideoUrl, link }) => {
    const { switchLanguage } = useLanguage()
    const As = link.startsWith("/") ? Link : "a"
    const linkProps = link.startsWith("/") ? { to: link } : { href: link, target: "_blank", rel: "noreferrer" }
    return (
        <As {...linkProps} className='pos-rel z-3 overflow-hidden br-10 ratio-1-2 t-pt100 m-pt150 bg-grey hover-image--zoom'>
            <div className='bg-image z-2 p60 t-p20 text-white border-box m-p20'>
                <div className='flex flex-col justify-between h-100 '>
                    <div>
                        <IconNText className='f600 flex align-center ' icon={icon}>
                            {switchLanguage(overlineJp, overline)}
                        </IconNText>
                        <H2 className='max-500 m-h2  '> {switchLanguage(titleJp, title)}</H2>
                    </div>
                    <div className='flex'>
                        <CtaButton as={"div"} children={switchLanguage(buttonTextJp, buttonText)} />
                    </div>
                </div>
            </div>
            <VideoOrImage mobileImage={imageMobile} videoWhen={backgroundVideoUrl} video={backgroundVideoUrl} image={image} className={"bg-image filter-brightness z-1 cover "} />
        </As>
    )
}

const News = ({ title, titleJp }) => {
    const { switchLanguage } = useLanguage()

    const { allNews } = useStaticQuery(graphql`
        query LatestNewsComponentQuery {
            allNews: allDatoCmsNews(limit: 3, sort: { fields: meta___createdAt }) {
                nodes {
                    id
                    introductionJp
                    introduction
                    name
                    nameJp
                    postContent
                    postContentJp
                    videoUrl
                    slug
                    image {
                        gatsbyImageData
                    }
                    category {
                        id
                        name
                        nameJp
                    }
                }
            }
        }
    `)

    return <LatestNews news={allNews.nodes} title={switchLanguage(titleJp, title)} />
}
