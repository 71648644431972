import React, { memo } from "react"
import { useLanguage } from "../hooks/useLanguage"
import Feature from "./feature"
import { H2, P } from "./fonts"

const ProductFeatures = memo(({ features, info, bgWhite = true }) => {
    const { switchLanguage } = useLanguage()
    return (
        <div className={bgWhite ? "bg-white" : ""}>
            <div className='grid grid-cols-2 pl60 pr60 max-1600 ma pb160 pt200 m-grid-1 t-grid-1 m-pl20 m-pr20 m-pt80 m-pb80 '>
                <div className='flex flex-col justify-center'>
                    <div className='max-450'>
                        <H2 className='mb30 '>{switchLanguage(info.titleJp, info.title)}</H2>
                        <P className='text-grey mb60 m-mb30 '>{switchLanguage(info.descriptionJp, info.description)}</P>
                        {/* TODO button to go here */}
                    </div>
                </div>
                <div className={"grid grid-cols-2  " + (features.length % 2 !== 0 ? "last-column-span-2" : "")}>
                    {features.map((feature, index) => {
                        return (
                            <div key={index} className='bb1g pt60 pb60 pr60 m-p20 m-pl0'>
                                <Feature feature={feature} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
})

export default memo(ProductFeatures)
