import { graphql } from "gatsby"
import React, { memo } from "react"
import { useLanguage } from "../hooks/useLanguage"
import { H3 } from "./fonts"
import Spacer from "./spacer"

function Testiomnal({ testiomnal, imgClassName, overline }) {
    const { switchLanguage } = useLanguage()
    return (
        <>
            <Spacer />
            <div className='pl60 pr60 pb160 pt80 text-center max-850 ma m-p20 t-p20'>
                {overline ? <div className='h3 m-h3 mb40 m0 op-50'>{overline}</div> : ""}
                <H3 className='m-h3 pb60'>{switchLanguage(testiomnal.quoteJp, testiomnal.quote)}</H3>
                <div className='max-650 bt1 ma pt40'>
                    <p className='m0 mb5 tiny text-grey'>{switchLanguage(testiomnal.detailJp, testiomnal.detail)}</p>
                    <p className='m0'>{switchLanguage(testiomnal.nameJp, testiomnal.name)}</p>
                </div>
            </div>
            <Spacer />
        </>
    )
}

export default memo(Testiomnal)

export const query = graphql`
    fragment Testiomnal on DatoCmsOffering {
        customerName
        customerNameJp
        customerDetail
        customerDetailJp
        quote
        quoteJp
    }
`
