import { graphql } from "gatsby"
import React from "react"
import ChangeHeaderInView from "../components/changeHeaderinView"
import HeroBanner from "../components/heroBanner"
import Layout from "../components/layout"
import { ModularContent } from "../components/modularContent"
import Seo from "../components/seo"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"

export default function ModularPages({ data: { page } }) {
    const { switchLanguage } = useLanguage()
    return (
        <Layout>
            <Seo title={page.heroTitle} />
            <HeroBanner title={switchLanguage(page.heroTitleJap, page.heroTitle)} image={page.heroImage} mobileImage={page.mobileHeroImage} video={page.heroVideoUrl} mobileVideo={page.mobileHeroVideoUrl} />
            <ChangeHeaderInView threshold={0.1} color={COLOR_STATES.DARK}>
                <ModularContent modules={page.modules} />
            </ChangeHeaderInView>
        </Layout>
    )
}

export const query = graphql`
    query ModulesPage($slug: String) {
        pageData: datoCmsModularPage(slug: { eq: $slug }) {
            subtitle
            subtitleJap
            mobileTitle
            mobileTitleJap
        }
        page: datoCmsModularPage(slug: { eq: $slug }) {
            heroTitle
            heroTitleJap
            heroImage {
                gatsbyImageData
            }
            mobileHeroImage {
                gatsbyImageData
            }
            heroVideoUrl
            mobileHeroVideoUrl
            modules {
                ... on DatoCmsLaptopCard {
                    id
                    title
                    titleJp
                    caption
                    captionJp
                    icon {
                        url
                    }
                    image {
                        gatsbyImageData
                    }
                    imageMobile {
                        gatsbyImageData
                    }
                }
                ... on DatoCmsLogoBar {
                    id
                    title
                    titleJp
                    logos {
                        id
                        icon {
                            gatsbyImageData
                            url
                        }
                    }
                }
                ... on DatoCmsHeading {
                    id
                    heading
                    headingJp
                    paragraph
                    paragraphJp
                }
                ... on DatoCmsSpacer {
                    id
                }
                ... on DatoCmsCardCarousel {
                    id
                    title
                    titleJp
                    cards {
                        buttonText
                        buttonTextJp
                        overline
                        overlineJp
                        title
                        titleJp
                        textLight
                        link
                        id
                        icon {
                            url
                        }
                        backgroundVideoUrl
                        imageMobile {
                            gatsbyImageData
                        }
                        image {
                            gatsbyImageData
                        }
                    }
                }
                ... on DatoCmsFullWidthCard {
                    id
                    buttonText
                    buttonTextJp
                    content
                    contentJp
                    link
                    title
                    titleJp
                    videoUrl
                    mobileVideoUrl
                    mobileImage {
                        gatsbyImageData
                    }
                    image {
                        gatsbyImageData
                    }
                    icon {
                        url
                    }
                }
                ... on DatoCmsLatestNews {
                    id
                    title
                    titleJp
                }
                ... on DatoCmsSubheading {
                    id
                    heading
                    headingJp
                    paragraph
                    paragraphJp
                }
                ... on DatoCmsTestimonial {
                    id
                    customerDetail
                    customerName
                    customerDetailJp
                    customerNameJp
                    quote
                    quoteJp
                }
                ... on DatoCmsIntroduction {
                    id
                    title
                    titleJp
                    description
                    descriptionJp
                    features {
                        id
                        name
                        nameJp
                        icon {
                            url
                        }
                        link
                    }
                }
                ... on DatoCmsTwoCard {
                    id
                    cards {
                        id
                        title
                        titleJp
                        video
                        icon {
                            url
                        }
                        image {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`
